<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="menuaccess"
        :search="search"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template
          v-for="(val, i) in dataCheckboxs"
          v-slot:[`item.dataCheckBox`+i]="{ item }"
        >
          <v-simple-checkbox
            :key="i"
            v-model="item[val].checked"
            color="purple"
            :ripple="false"
            :disabled="!item[val].enable"
            @input="checkboxClick(item[val])"
          />
        </template>
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-8 py-8"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
              />
            </v-col>
            <v-spacer />
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      headers: [
        { text: '', align: 'start', value: 'alias' },
      ],
      menuaccess: [],
      isAllowVerification: false,
      isAllowEdit: false,
      dataCheckboxs: [],
      page: 1,
      pageCount: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, 1000)
    },

    methods: {
      initialize (page, limit) {
        const vm = this

        axios.post('/v1/t/partnerMenu/access').then((res) => {
          if (res.data.status === 200) {
            const headers = res.data.data.header
            const menus = res.data.data.menu
            // vm.pageCount = res.data.data.pagination.page_total

            headers.map((val, i) => {
              const dataHeader = {}
              dataHeader.text = val.div_name + ' - ' + val.pos_name
              dataHeader.value = 'dataCheckBox' + i
              vm.dataCheckboxs.push('dataCheckBox' + i)
              vm.headers.push(dataHeader)
            })

            menus.map((val, i) => {
              const dataMenu = {}
              const listCheckBox = val.checkbox
              dataMenu.alias = val.name
              listCheckBox.map((val, i) => {
                dataMenu['dataCheckBox' + i] = val
              })
              vm.menuaccess.push(dataMenu)
            })
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      checkboxClick (item) {
        const requestBody = {
          c_hash: item.mix_hash,
        }
        axios.post('/v1/t/partnerMenu/access/modify', requestBody).then((res) => {
          this.$toast.success(res.data.message)
        })
      },

      onChangePage (e) {
        // this.initialize(e, this.itemsPerPage)
      },
    },
  }
</script>
